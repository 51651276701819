import request from '@/utils/request'
// import JSEncrypt from "jsencrypt";
// const encryptor = new JSEncrypt()
// const publicKey = 'LhGOknKmD8vBBDuLQmavnPdhgxkPlKUhEZS1wO542Cc9BHOyzWJdUlbEAlwRMlsUUxIQwMGNxtJw2Vwf1SN9aXutoSIdnjd413hEa713m8eQulZ9U0px6kYcNE2IgylDhuGhqEQ7qbQJsnpkNnk37kwqnh29u3DoH+cLauUXSTk='
// encryptor.setPublicKey(publicKey)

// let decrypt = new JSEncrypt()
// const privateKey = "MIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQCIelUFQYkVfmrNIGfq7hAROL7/VWYWV8tVmRiDJKEOXN8kJfh4BJbWOS/CQrpiBfuRxD3TUrf60XBMVG2I1iTBFQHLKf+qc/a1+3ilwfN7uMtddc9/XPff/BAMmPgV0vKBoPxPVGRK4VJsl06wI2giuWECFTVXhk1IqWZXn9Y6mlNlWUnaCuRp6ihzbFjF5yZQK6FDqBBlJ5HPBhjjGu45PgQYK3CRDLHuD6X05gtY3a6xIy0JG1M3y4XvIALZCAZo7yHWNnxX2oDXDvHCd6rTepNNI+fTSITnugQlaozahFnYcMbihp40IuvKNmTX693LXXjxlol5p7JwMRmcpmPFAgMBAAECggEAUW81RgKZyoJ5NdatSygr3CrRBMgvNwvB31yPnMkBdPGDkC07+lk6q67JyY9JaW4Kcyr2fR7xeq735APIrAqjObbQMCrqTAoP3vyjO7zkDSHx7t16J5QQr8zWwr2P4m3Rl/m4M5m2kluqc/NA16b43ydgM/71qjkP6lhyIbzU9nf6Gj0YdKi1iR2yIy04cbunY6UW7iC5OeX3uQHzb4RAUwY4trrnkRuPCmRtq0VhKGPndjsEBGwx/7IP7hirXT8XloGrub4smQvNCVD9mJ8K6IZcMD1QNbfTIU5FO9ukI+7CkxNM80Lwo8LRXJfZcDPWs53kZdx3zo5IFKV5BHz8DQKBgQDDh3COC160AzwOhSKl5rjyKOIs7HLM5YFRfjgL30xonG6LP1T22csEhq+Dn7FUc84oc4IZA2CYTd7562faSVovuIGTjaDvYej7kGzakR1tYELjDB1U4hq+i5FyEG5T7cBw5Y9dSHsMPXmWmPx7cpbmZcz2gU0JsBNjCNnAWmFk2wKBgQCyr6aBQdmqijTQI8bdAVbgTeG/eSCsNJlt3b8Cu9zs2vCB3k59ddmRWI7KW1A/AQvisHAuUsD+CsRtD86Jr3I1NbgV/u/dFOR/xq6dVShVML/YENVyP7DDqXhB4wRM/G6AVKB1S+7vwBSaQUOCNby43kwa9BnvRGkKU2JIknhr3wKBgQC+3OXE8lpWc9XbAqDIuwiL8daeLYz+b4WlbAT/qdBnSVQq/v5MHFstpt+LJ4YtPwHdwKsdCYvwAAuJf/4XOYUckgmCZDzVj4iij9+CFydXjiCci0XShwe+OnUWl+1qZel1CtDAKTFbyGJ43AuR4VBlxLenZJ4zt7mk8Y3FUzRBAwKBgF/sZ6hQH3erxfpOmn0eMPMtHBlb/ywy+ILAXcAr9ce5KQ+utdgoIeoi7lS3fHa2KxlAuphDmJNIWHZr2+aSJu9lrTMxrraydpAZqrePdZ12FCb7snooss329jX4luB1SyTdSV5uqxBNv0Gn84O9MVjVDWoSzLFjujfu1346EydlAoGATuLU9WLipJJsJb1xK/hVCd0v9RFRTH84SPA4lpORkJ5QliZJN/6NbwMwPez89HkZvgU8rjErrOeaFEwPPihPzJ6VraMkGRkb6VPVv5M/aPFHRjp0vl0IB3Ub83C5O44LcCoIpKkMSZcIZeKHR+ZFmXhUnKL9Gc2qf57kw4BtgbE="
//   decrypt.setPrivateKey(privateKey)
// 
// 获取秘钥
export function getKey(data) {
  return request({
    url: '/uplusService/common/getKeyForTool2',
    method: 'get',
    params: {
      userId: data
    }
  })
}
// 血压
export function getBloodPressureAvg(data) {
  let key = sessionStorage.getItem("offkey");

  return request({
    url: '/uplusService/awe/queryBloodPressureAvg',
    method: 'get',
    params: {
      offUserId: data,
      data: key
    }
  })
}
// 血氧
export function getBloodOxygenAvg(data) {
  let key = sessionStorage.getItem("key");

  return request({
    url: '/uplusService/awe/queryBloodOxygenAvg',
    method: 'get',
    params: {
      haierUserId: data,
      data: key
    }
  })
}
// 血糖
export function getBloodGlucoseAvg(data) {
  let key = sessionStorage.getItem("key");

  return request({
    url: '/uplusService/awe/queryBloodGlucoseAvg',
    method: 'get',
    params: {
      haierUserId: data,
      data: key
    }
  })
}
// 尿酸
export function getUricAcidAvg(data) {
  let key = sessionStorage.getItem("key");

  return request({
    url: '/uplusService/awe/queryUricAcidAvg',
    method: 'get',
    params: {
      haierUserId: data,
      data: key
    }
  })
}
// 健康
export function getJinFanAvg(data) {
  let key = sessionStorage.getItem("key");

  return request({
    url: '/uplusService/awe/queryJinFanAvg',
    method: 'get',
    params: {
      haierUserId: data,
      data: key
    }
  })
}
// 获取分数
export function getScore(data) {
  let key = sessionStorage.getItem("key");
  let offkey = sessionStorage.getItem("offkey");
  let dataList = data
  dataList.data1 = key
  dataList.data2 = offkey

  return request({
    url: '/uplusService/awe/score',
    method: 'get',
    params: dataList,
  })
}
