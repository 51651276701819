<template>
  <div id="app">
    <div class="header" @click="initData"></div>
    <div class="border">
      <div class="border_left"></div>
      <div class="border_right"></div>
    </div>
    <div class="container_left" v-show="show === 'normal'">
      <div class="left_title">
        <img src="./assets/left-title.png" alt="" />
      </div>
      <div class="left_content">
        <div class="item">
          <div class="item_left">
            <div class="item_top">
              <img src="./assets/item-1.png" alt="" />
              <div class="item_text">{{ gaoyaNum }}/{{ diyaNum }}</div>
            </div>
            <div class="progress" style="margin-bottom: 17px">
              <div ref="gaoyaEl" class="cle"></div>
            </div>
            <div class="progress">
              <div ref="diyaEl" class="cle"></div>
            </div>
          </div>
          <div id="line_chart1" style="width: 460px; height: 80px"></div>
        </div>
        <div class="item">
          <div class="item_left">
            <div class="item_top">
              <img src="./assets/item-2.png" alt="" />
              <div class="item_text">{{ xinlvNum }}</div>
            </div>
            <div class="progress">
              <div ref="xinlvEl" class="cle"></div>
            </div>
          </div>
          <div id="line_chart2" style="width: 460px; height: 80px"></div>
        </div>
        <div class="item" v-show="false">
          <div class="item_left">
            <div class="item_top">
              <img src="./assets/item-3.png" alt="" />
              <div class="item_text">{{ xueyangNum }}</div>
            </div>
            <div class="progress">
              <div class="cle" ref="xueyangEl"></div>
            </div>
          </div>
          <div id="line_chart3" style="width: 460px; height: 80px"></div>
        </div>
        <div class="item" v-show="false">
          <div class="item_left">
            <div class="item_top">
              <img src="./assets/item-4.png" alt="" />
              <div class="item_text">{{ xuetangNum }}</div>
            </div>
            <div class="progress">
              <div class="cle" ref="xuetangEl"></div>
            </div>
          </div>
          <div id="line_chart4" style="width: 460px; height: 80px"></div>
        </div>
        <div class="item">
          <div class="item_left">
            <div class="item_top">
              <img src="./assets/item-5.png" alt="" />
              <div class="item_text">{{ niaosuanNum }}</div>
            </div>
            <div class="progress">
              <div class="cle" ref="niaosuanEl"></div>
            </div>
          </div>
          <div id="line_chart5" style="width: 460px; height: 80px"></div>
        </div>
        <div class="item">
          <div class="item_left">
            <div class="item_top">
              <img src="./assets/item-6.png" alt="" />
              <div class="item_text">{{ pilaoNum }}</div>
            </div>
            <div class="progress">
              <div class="cle" ref="pilaoEl"></div>
            </div>
          </div>
          <div id="line_chart6" style="width: 460px; height: 80px"></div>
        </div>
      </div>
    </div>
    <div class="container_left_height" v-show="show === 'height'">
      <div class="left_title">
        <img src="./assets/left-title1.png" alt="" />
        <img src="./assets/cancel.png" alt="" @click="showIndex" />
      </div>
      <div class="left_content">
        <div class="left">
          <div class="item">
            <div class="item_top">
              <img src="./assets/item-7.png" alt="" />
            </div>
            <div class="item_title">
              <p class="icon"></p>
              <p class="text">心跳频率改变</p>
            </div>
            <div class="item_text">
              心率增快，心舒期末在主动脉中存流的血量增多，导致低压高。
            </div>
            <div class="item_title">
              <p class="icon"></p>
              <p class="text">压力</p>
            </div>
            <div class="item_text">
              交感神经过度兴奋，导致舒张压增高，在青中年群体中较为常见。
            </div>
            <div class="item_title">
              <p class="icon"></p>
              <p class="text">代谢障碍</p>
            </div>
            <div class="item_text">
              胆固醇、甘油三酯、血糖代谢异常对血管弹性造成影响，间接升高舒张压。
            </div>
          </div>
          <div class="item">
            <div class="item_top">
              <img src="./assets/item-8.png" alt="" />
            </div>
            <div class="item_text">
              达到高血压危象
              (≥180/120mmHg)，可能出现卒中、意识丧失、失忆、视物模糊、心肌梗死、肾功能损害、心绞痛、肺水肿、主动脉夹层、子痫等。
              诱发脑血管病、冠心病、高血压性心脏病、高血压脑病、肾功能障碍等。
            </div>
          </div>
        </div>
        <div class="right">
          <div class="item">
            <div class="item_top">
              <img src="./assets/item-9.png" alt="" />
            </div>
            <div class="item_title">
              <p class="icon"></p>
              <p class="text">饮食建议</p>
            </div>
            <div class="item_text">
              <span>减少钠盐，增加钾摄入：</span
              >钠盐每日不超过6克，清淡饮食；多吃蘑菇、菠菜、香蕉、樱桃等富含钾的蔬菜水果。
            </div>
            <div class="item_text">
              <span>补充膳食纤维和优质蛋白：</span
              >富含膳食纤维的全谷物及蔬菜水果；富含优质蛋白的鸡蛋、牛奶、瘦肉等。
            </div>
            <div class="item_text">
              <span>适度补钙：</span
              >可适当增加酸奶、低乳糖奶、豆腐、低盐豆干、豆浆等低脂乳制品和豆制品摄入。
            </div>
            <div class="item_title">
              <p class="icon"></p>
              <p class="text">运动建议</p>
            </div>
            <div class="item_text">
              <span>运动频次：</span
              >建议每周主动运动150~300分钟，每次30~60分钟，每周5-7次。以中等强度的有氧运动为宜。<br />
              <span>运动类型：</span
              >根据个人兴趣和身体状况选择步行、慢跑、骑车、游泳、健美操、跳舞、非比赛性划船等有氧运动。
            </div>
            <div class="item_title">
              <p class="icon"></p>
              <p class="text">生活方式</p>
            </div>
            <div class="item_text">
              <span>增加体力活动，保持正常体重：</span>
              建议BMI控制在24kg/㎡以内，男性腰围控制在85cm以内，女性80cm以内。<br />
              <span style="color: white">限制饮酒：</span
              >成年男性每日酒精摄入量应不高于25g，女性不高于15g。
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container_left_low" v-show="show === 'low'">
      <div class="left_title1">
        <img src="./assets/left-title.png" alt="" />
        <img src="./assets/cancel.png" alt="" @click="showIndex" />
      </div>
      <div class="left_content">
        <div class="left">
          <div class="item">
            <div class="item_top">
              <img src="./assets/item-10.png" alt="" />
            </div>
            <div class="item_title">
              <p class="icon"></p>
              <p class="text">生理性低血压</p>
            </div>
            <div class="item_text">
              部分健康人的血压比正常低且长期稳定，没有任何症状，对生活无影响，多见于某些大体力劳动者、身体偏瘦的女性，也与遗传有关。
            </div>
            <div class="item_title">
              <p class="icon"></p>
              <p class="text">继发性低血压</p>
            </div>
            <div class="item_text">
              慢性营养不良、贫血、糖尿病、更年期、心脏病、肿瘤等慢性病可能造成低血压，此类情况需及时就诊；降压药、抗抑郁药、安眠药等也可能引发低血压。
            </div>
            <div class="item_title">
              <p class="icon"></p>
              <p class="text">血管的顺应性差</p>
            </div>
            <div class="item_text">
              回流心脏的血量少，血管扩张，血液流速减慢，血量不足造成舒张压低。
            </div>
          </div>
          <div class="item">
            <div class="item_top">
              <img src="./assets/item-8.png" alt="" />
            </div>
            <div class="item_text">
              心肌缺血：舒张压&lt;60mmHg，心脏冠脉的灌注压不足，容易因心肌缺血诱发冠心病。
            </div>
          </div>
        </div>
        <div class="right">
          <div class="item">
            <div class="item_top">
              <img src="./assets/item-9.png" alt="" />
            </div>
            <div class="item_title">
              <p class="icon"></p>
              <p class="text">饮食建议</p>
            </div>
            <div class="item_text">
              <span>均衡饮食：</span
              >既要保证有一定主食量、蛋白量，又要有一定的蔬菜、水果，主要摄入肉类、鱼类、禽类、红肉、牛奶、羊奶、鸡蛋、鸭蛋、鹌鹑蛋等。
            </div>
            <div class="item_title">
              <p class="icon"></p>
              <p class="text">运动建议</p>
            </div>
            <div class="item_text">
              <span>积极运动：</span
              >跑步、跳绳、踢键子、打乒乓球、篮球、足球、排球、网球、台球等，或者跳绳等简单运动，有助于心血管调节。
            </div>
            <div class="item_title">
              <p class="icon"></p>
              <p class="text">生活方式</p>
            </div>
            <div class="item_text">
              <span>缓慢改变身体位置：</span>
              起床或下蹲后，应缓慢起身，不要突然改变体位，防止血压突然下降。提举重物、如厕后也要缓慢起立。
              <br />
              <span>避免站立过久：</span
              >避免久处缺氧环境或者闷热的环境中，并避免长时间站立。<br />
              <span>尽量避免饮酒：</span
              >酒精会导致组织脱水，即使饮酒量不高也可导致血压降低。

              <br />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container_right" v-show="show === 'normal'">
      <div class="right_title">
        <img src="./assets/right-title.png" alt="" />
      </div>

      <div class="right_item">
        <div class="text">成员1组</div>
        <el-select
          v-model="userSelect.user1"
          placeholder="点击选择"
          :popper-append-to-body="false"
          @change="(value) => userChange(value, 1)"
        >
          <el-option
            v-for="item in userList1"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div class="right_item">
        <div class="text">成员2组</div>
        <el-select
          v-model="userSelect.user2"
          placeholder="点击选择"
          :popper-append-to-body="false"
          @change="(value) => userChange(value, 2)"
        >
          <el-option
            v-for="item in userList2"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div class="right_item">
        <div class="text">成员3组</div>
        <el-select
          v-model="userSelect.user3"
          placeholder="点击选择"
          :popper-append-to-body="false"
          @change="(value) => userChange(value, 3)"
        >
          <el-option
            v-for="item in userList3"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div class="right_item">
        <div class="text">成员4组</div>
        <el-select
          v-model="userSelect.user4"
          placeholder="点击选择"
          :popper-append-to-body="false"
          @change="(value) => userChange(value, 4)"
        >
          <el-option
            v-for="item in userList4"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div class="right_item">
        <div class="text">成员5组</div>
        <el-select
          v-model="userSelect.user5"
          placeholder="点击选择"
          :popper-append-to-body="false"
          @change="(value) => userChange(value, 5)"
        >
          <el-option
            v-for="item in userList5"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div class="right_item">
        <div class="text">成员6组</div>
        <el-select
          v-model="userSelect.user6"
          placeholder="点击选择"
          :popper-append-to-body="false"
          @change="(value) => userChange(value, 6)"
        >
          <el-option
            v-for="item in userList6"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
    </div>
    <div class="container_right_height" v-show="show !== 'normal'">
      <div class="right_title">
        <img src="./assets/right-title.png" alt="" />
      </div>
      <div id="cleChart1"></div>
      <div class="tip">
        <p>最近1次健康测评</p>
      </div>
      <div class="tip1" v-if="showStatus === 'height'">
        <p>您的血压偏高</p>
        <p>请及时关注并注意保持良好生活习惯</p>
      </div>
      <div class="tip1" v-if="showStatus === 'low'">
        <p>您的血压偏低</p>
        <p>请及时关注并注意保持良好生活习惯</p>
      </div>
      <div class="button" @click="showIndex">
        <img src="./assets/button-1.png" alt="" />
      </div>
    </div>
    <el-dialog
      title="配置查询id"
      :visible.sync="dialogVisible"
      append-to-body
      :close-on-click-modal="false"
      width="30%"
    >
      <el-form ref="form" :model="idList" label-width="120px">
        <el-form-item label="爸爸haierUserId">
          <el-input v-model="idList.DadHaierUserId"></el-input> </el-form-item
        ><el-form-item label="爸爸offUserId">
          <el-input v-model="idList.DadOffUserId"></el-input>
        </el-form-item>
        <el-form-item label="妈妈haierUserId">
          <el-input v-model="idList.MumHaierUserId"></el-input> </el-form-item
        ><el-form-item label="妈妈offUserId">
          <el-input v-model="idList.MumOffUserId"></el-input> </el-form-item
        ><el-form-item label="刷新方式">
          <el-select v-model="idList.type" placeholder="请选择">
            <el-option label="自动" :value="0"> </el-option>
            <el-option label="手动" :value="1"> </el-option>
          </el-select> </el-form-item
        ><el-form-item label="刷新频率(秒)">
          <el-input
            :disabled="idList.type === 1"
            v-model="idList.speed"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="optionChange">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import * as echarts from "echarts";
import {
  getBloodPressureAvg,
  getBloodOxygenAvg,
  getJinFanAvg,
  getScore,
  getKey,
} from "@/utils/api.js";
let lineChart1;
let lineChart2;
let lineChart5;
let lineChart6;
export default {
  name: "App",
  data() {
    return {
      dialogVisible: false,
      idList: {
        DadHaierUserId: 33713367,
        DadOffUserId: 33713367,
        MumHaierUserId: 33713367,
        MumOffUserId: 33713367,
        speed: 2,
        type: 0,
      },
      userSelect: {
        user1: null,
        user2: null,
        user3: null,
        user4: null,
        user5: null,
        user6: null,
      },
      userList1: [
        {
          id: 1,
          name: "孙玟玟",
        },
        {
          id: 2,
          name: "王晴晴",
        },
        {
          id: 3,
          name: "吴坤",
        },
        {
          id: 4,
          name: "刘宇",
        },
        {
          id: 5,
          name: "马筱婷",
        },
        {
          id: 6,
          name: "徐丽丽",
        },
      ],
      userList2: [
        {
          id: 1,
          name: "刘娜",
        },
        {
          id: 2,
          name: "闫鲁平",
        },
        {
          id: 3,
          name: "王雨萌",
        },
        {
          id: 4,
          name: "鲍榕榕",
        },
        {
          id: 5,
          name: "付圆圆",
        },
      ],
      userList3: [
        {
          id: 1,
          name: "隋玉艺",
        },
        {
          id: 2,
          name: "范敏如",
        },
        {
          id: 3,
          name: "薛新颖",
        },
        {
          id: 4,
          name: "李新静",
        },
        {
          id: 5,
          name: "石超",
        },
      ],
      userList4: [
        {
          id: 1,
          name: "楚子真",
        },
        {
          id: 2,
          name: "郝友春",
        },
        {
          id: 3,
          name: "张雨晴",
        },
        {
          id: 4,
          name: "张羽佳",
        },
        {
          id: 5,
          name: "张俊如",
        },
      ],
      userList5: [
        {
          id: 1,
          name: "孟婧雯",
        },
        {
          id: 2,
          name: "张晓金",
        },
        {
          id: 3,
          name: "解西倩",
        },
        {
          id: 4,
          name: "张凯悦",
        },
        {
          id: 5,
          name: "钟文雪",
        },
        {
          id: 6,
          name: "王耀翎",
        },
      ],
      userList6: [
        {
          id: 1,
          name: "韩露",
        },
        {
          id: 2,
          name: "王冉",
        },
        {
          id: 3,
          name: "姚梦梦",
        },
        {
          id: 4,
          name: "吴爱琦",
        },
        {
          id: 5,
          name: "赵艺可",
        },
      ],
      idType: "dad",
      gaoyaNum: null,
      diyaNum: null,
      xinlvNum: null,
      xueyangNum: null,
      xuetangNum: null,
      niaosuanNum: null,
      pilaoNum: null,
      show: "normal",
      showStatus: "normal",
      showText: null,
      tabList: [
        {
          label: "爸爸",
          isActive: true,
          value: "dad",
        },
        {
          label: "妈妈",
          isActive: false,
          value: "mum",
        },
      ],
      option: {
        grid: {
          left: "0%",
          width: "99%",
        },

        xAxis: {
          type: "category",
          data: ["01/12", "02/12", "03/12", "04/12", "05/12", "06/12", "07/12"],
          axisLabel: {
            //x轴文字的配置
            show: true,
            textStyle: {
              color: "#fff",
            },
          },
        },
        yAxis: {
          type: "value",
          show: false,
        },
        series: [
          {
            data: [820, 932, 901, 934, 1290, 1330, 1320],
            type: "line",
            smooth: true,
            symbolSize: 6,
            itemStyle: {
              normal: {
                color: "white", //折线点的颜色
              },
            },
            lineStyle: {
              color: "#FCFE54",
            },
          },
          {
            data: [120, 240, 130, 280, 560, 890, 1000],
            type: "line",
            smooth: true,
            symbolSize: 6,
            itemStyle: {
              normal: {
                color: "white", //折线点的颜色
              },
            },

            lineStyle: {
              color: "#74F9CE",
            },
          },
        ],
      },
      option1: {
        grid: {
          left: "0%",
          width: "99%",
        },

        xAxis: {
          type: "category",
          data: ["01/12", "02/12", "03/12", "04/12", "05/12", "06/12", "07/12"],
          axisLabel: {
            //x轴文字的配置
            show: true,
            textStyle: {
              color: "#fff",
            },
          },
        },
        yAxis: {
          type: "value",
          show: false,
        },
        series: [
          {
            data: [120, 240, 130, 280, 560, 890, 1000],
            type: "line",
            smooth: true,
            symbolSize: 6,
            itemStyle: {
              normal: {
                color: "white", //折线点的颜色
              },
            },

            lineStyle: {
              color: "#74F9CE",
            },
          },
        ],
      },

      liuData: [
        {
          data: [
            {
              measureDate: "06/24",
              highPressure: "128",
              lowPressure: "88",
              pulse: "70",
              bloodOxygen: "50",
              fatigueIndex: "30",
            },
            {
              measureDate: "06/24",
              highPressure: "138",
              lowPressure: "92",
              pulse: "74",
              bloodOxygen: "55",
              fatigueIndex: "32",
            },
            {
              measureDate: "06/24",
              highPressure: "118",
              lowPressure: "84",
              pulse: "78",
              bloodOxygen: "53",
              fatigueIndex: "35",
            },
            {
              measureDate: "06/24",
              highPressure: "148",
              lowPressure: "80",
              pulse: "85",
              bloodOxygen: "56",
              fatigueIndex: "37",
            },
            {
              measureDate: "06/24",
              highPressure: "108",
              lowPressure: "100",
              pulse: "90",
              bloodOxygen: "57",
              fatigueIndex: "40",
            },
            {
              measureDate: "06/24",
              highPressure: "138",
              lowPressure: "90",
              pulse: "100",
              bloodOxygen: "58",
              fatigueIndex: "42",
            },
            {
              measureDate: "06/24",
              highPressure: "128",
              lowPressure: "92",
              pulse: "105",
              bloodOxygen: "59",
              fatigueIndex: "45",
            },
            {
              measureDate: "06/24",
              highPressure: "118",
              lowPressure: "88",
              pulse: "100",
              bloodOxygen: "61",
              fatigueIndex: "48",
            },
            {
              measureDate: "06/24",
              highPressure: "120",
              lowPressure: "85",
              pulse: "90",
              bloodOxygen: "62",
              fatigueIndex: "50",
            },
          ],
          score: {
            bloodPressure: {
              highPressure: "128",
              lowPressure: "85",
            },
            pulse: "90",
            bloodOxygen: "62",
            fatigueIndex: "50",
          },
        },
        {
          data: [
            {
              measureDate: "06/24",
              highPressure: "138",
              lowPressure: "92",
              pulse: "74",
              bloodOxygen: "55",
              fatigueIndex: "32",
            },
            {
              measureDate: "06/24",
              highPressure: "118",
              lowPressure: "84",
              pulse: "78",
              bloodOxygen: "53",
              fatigueIndex: "35",
            },
            {
              measureDate: "06/24",
              highPressure: "148",
              lowPressure: "80",
              pulse: "85",
              bloodOxygen: "56",
              fatigueIndex: "37",
            },
            {
              measureDate: "06/24",
              highPressure: "108",
              lowPressure: "100",
              pulse: "90",
              bloodOxygen: "57",
              fatigueIndex: "40",
            },
            {
              measureDate: "06/24",
              highPressure: "138",
              lowPressure: "90",
              pulse: "100",
              bloodOxygen: "58",
              fatigueIndex: "42",
            },
            {
              measureDate: "06/24",
              highPressure: "128",
              lowPressure: "92",
              pulse: "105",
              bloodOxygen: "59",
              fatigueIndex: "45",
            },
            {
              measureDate: "06/24",
              highPressure: "118",
              lowPressure: "88",
              pulse: "100",
              bloodOxygen: "61",
              fatigueIndex: "48",
            },
            {
              measureDate: "06/24",
              highPressure: "120",
              lowPressure: "85",
              pulse: "110",
              bloodOxygen: "62",
              fatigueIndex: "50",
            },
            {
              measureDate: "06/24",
              highPressure: "130",
              lowPressure: "88",
              pulse: "112",
              bloodOxygen: "64",
              fatigueIndex: "52",
            },
          ],
          score: {
            bloodPressure: {
              highPressure: "130",
              lowPressure: "88",
            },
            pulse: "112",
            bloodOxygen: "64",
            fatigueIndex: "52",
          },
        },
        {
          data: [
            {
              measureDate: "06/24",
              highPressure: "118",
              lowPressure: "84",
              pulse: "78",
              bloodOxygen: "53",
              fatigueIndex: "35",
            },
            {
              measureDate: "06/24",
              highPressure: "148",
              lowPressure: "80",
              pulse: "85",
              bloodOxygen: "56",
              fatigueIndex: "37",
            },
            {
              measureDate: "06/24",
              highPressure: "108",
              lowPressure: "100",
              pulse: "90",
              bloodOxygen: "57",
              fatigueIndex: "40",
            },
            {
              measureDate: "06/24",
              highPressure: "138",
              lowPressure: "90",
              pulse: "100",
              bloodOxygen: "58",
              fatigueIndex: "42",
            },
            {
              measureDate: "06/24",
              highPressure: "128",
              lowPressure: "92",
              pulse: "105",
              bloodOxygen: "59",
              fatigueIndex: "45",
            },
            {
              measureDate: "06/24",
              highPressure: "118",
              lowPressure: "88",
              pulse: "100",
              bloodOxygen: "61",
              fatigueIndex: "48",
            },
            {
              measureDate: "06/24",
              highPressure: "120",
              lowPressure: "85",
              pulse: "110",
              bloodOxygen: "62",
              fatigueIndex: "50",
            },
            {
              measureDate: "06/24",
              highPressure: "130",
              lowPressure: "88",
              pulse: "112",
              bloodOxygen: "64",
              fatigueIndex: "52",
            },
            {
              measureDate: "06/24",
              highPressure: "138",
              lowPressure: "92",
              pulse: "115",
              bloodOxygen: "66",
              fatigueIndex: "54",
            },
          ],
          score: {
            bloodPressure: {
              highPressure: "138",
              lowPressure: "92",
            },
            pulse: "115",
            bloodOxygen: "66",
            fatigueIndex: "54",
          },
        },
        {
          data: [
            {
              measureDate: "06/24",
              highPressure: "148",
              lowPressure: "80",
              pulse: "85",
              bloodOxygen: "56",
              fatigueIndex: "37",
            },
            {
              measureDate: "06/24",
              highPressure: "108",
              lowPressure: "100",
              pulse: "90",
              bloodOxygen: "57",
              fatigueIndex: "40",
            },
            {
              measureDate: "06/24",
              highPressure: "138",
              lowPressure: "90",
              pulse: "100",
              bloodOxygen: "58",
              fatigueIndex: "42",
            },
            {
              measureDate: "06/24",
              highPressure: "128",
              lowPressure: "92",
              pulse: "105",
              bloodOxygen: "59",
              fatigueIndex: "45",
            },
            {
              measureDate: "06/24",
              highPressure: "118",
              lowPressure: "88",
              pulse: "100",
              bloodOxygen: "61",
              fatigueIndex: "48",
            },
            {
              measureDate: "06/24",
              highPressure: "120",
              lowPressure: "85",
              pulse: "110",
              bloodOxygen: "62",
              fatigueIndex: "50",
            },
            {
              measureDate: "06/24",
              highPressure: "130",
              lowPressure: "88",
              pulse: "112",
              bloodOxygen: "64",
              fatigueIndex: "52",
            },
            {
              measureDate: "06/24",
              highPressure: "138",
              lowPressure: "92",
              pulse: "115",
              bloodOxygen: "66",
              fatigueIndex: "54",
            },
            {
              measureDate: "06/24",
              highPressure: "132",
              lowPressure: "84",
              pulse: "113",
              bloodOxygen: "64",
              fatigueIndex: "52",
            },
          ],
          score: {
            bloodPressure: {
              highPressure: "132",
              lowPressure: "84",
            },
            pulse: "113",
            bloodOxygen: "64",
            fatigueIndex: "52",
          },
        },
        {
          data: [
            {
              measureDate: "06/24",
              highPressure: "108",
              lowPressure: "100",
              pulse: "90",
              bloodOxygen: "57",
              fatigueIndex: "40",
            },
            {
              measureDate: "06/24",
              highPressure: "138",
              lowPressure: "90",
              pulse: "100",
              bloodOxygen: "58",
              fatigueIndex: "42",
            },
            {
              measureDate: "06/24",
              highPressure: "128",
              lowPressure: "92",
              pulse: "105",
              bloodOxygen: "59",
              fatigueIndex: "45",
            },
            {
              measureDate: "06/24",
              highPressure: "118",
              lowPressure: "88",
              pulse: "100",
              bloodOxygen: "61",
              fatigueIndex: "48",
            },
            {
              measureDate: "06/24",
              highPressure: "120",
              lowPressure: "85",
              pulse: "110",
              bloodOxygen: "62",
              fatigueIndex: "50",
            },
            {
              measureDate: "06/24",
              highPressure: "130",
              lowPressure: "88",
              pulse: "112",
              bloodOxygen: "64",
              fatigueIndex: "52",
            },
            {
              measureDate: "06/24",
              highPressure: "138",
              lowPressure: "92",
              pulse: "115",
              bloodOxygen: "66",
              fatigueIndex: "54",
            },
            {
              measureDate: "06/24",
              highPressure: "132",
              lowPressure: "84",
              pulse: "113",
              bloodOxygen: "64",
              fatigueIndex: "52",
            },
            {
              measureDate: "06/24",
              highPressure: "148",
              lowPressure: "80",
              pulse: "116",
              bloodOxygen: "67",
              fatigueIndex: "54",
            },
          ],
          score: {
            bloodPressure: {
              highPressure: "148",
              lowPressure: "80",
            },
            pulse: "116",
            bloodOxygen: "67",
            fatigueIndex: "54",
          },
        },
        {
          data: [
            {
              measureDate: "06/24",
              highPressure: "138",
              lowPressure: "90",
              pulse: "100",
              bloodOxygen: "58",
              fatigueIndex: "42",
            },
            {
              measureDate: "06/24",
              highPressure: "128",
              lowPressure: "92",
              pulse: "105",
              bloodOxygen: "59",
              fatigueIndex: "45",
            },
            {
              measureDate: "06/24",
              highPressure: "118",
              lowPressure: "88",
              pulse: "100",
              bloodOxygen: "61",
              fatigueIndex: "48",
            },
            {
              measureDate: "06/24",
              highPressure: "120",
              lowPressure: "85",
              pulse: "110",
              bloodOxygen: "62",
              fatigueIndex: "50",
            },
            {
              measureDate: "06/24",
              highPressure: "130",
              lowPressure: "88",
              pulse: "112",
              bloodOxygen: "64",
              fatigueIndex: "52",
            },
            {
              measureDate: "06/24",
              highPressure: "138",
              lowPressure: "92",
              pulse: "115",
              bloodOxygen: "66",
              fatigueIndex: "54",
            },
            {
              measureDate: "06/24",
              highPressure: "132",
              lowPressure: "84",
              pulse: "113",
              bloodOxygen: "64",
              fatigueIndex: "52",
            },
            {
              measureDate: "06/24",
              highPressure: "148",
              lowPressure: "80",
              pulse: "116",
              bloodOxygen: "67",
              fatigueIndex: "54",
            },
            {
              measureDate: "06/24",
              highPressure: "138",
              lowPressure: "90",
              pulse: "120",
              bloodOxygen: "70",
              fatigueIndex: "56",
            },
          ],
          score: {
            bloodPressure: {
              highPressure: "138",
              lowPressure: "90",
            },
            pulse: "120",
            bloodOxygen: "70",
            fatigueIndex: "56",
          },
        },
        {
          data: [
            {
              measureDate: "06/24",
              highPressure: "128",
              lowPressure: "92",
              pulse: "105",
              bloodOxygen: "59",
              fatigueIndex: "45",
            },
            {
              measureDate: "06/24",
              highPressure: "118",
              lowPressure: "88",
              pulse: "100",
              bloodOxygen: "61",
              fatigueIndex: "48",
            },
            {
              measureDate: "06/24",
              highPressure: "120",
              lowPressure: "85",
              pulse: "110",
              bloodOxygen: "62",
              fatigueIndex: "50",
            },
            {
              measureDate: "06/24",
              highPressure: "130",
              lowPressure: "88",
              pulse: "112",
              bloodOxygen: "64",
              fatigueIndex: "52",
            },
            {
              measureDate: "06/24",
              highPressure: "138",
              lowPressure: "92",
              pulse: "115",
              bloodOxygen: "66",
              fatigueIndex: "54",
            },
            {
              measureDate: "06/24",
              highPressure: "132",
              lowPressure: "84",
              pulse: "113",
              bloodOxygen: "64",
              fatigueIndex: "52",
            },
            {
              measureDate: "06/24",
              highPressure: "148",
              lowPressure: "80",
              pulse: "116",
              bloodOxygen: "67",
              fatigueIndex: "54",
            },
            {
              measureDate: "06/24",
              highPressure: "138",
              lowPressure: "90",
              pulse: "120",
              bloodOxygen: "70",
              fatigueIndex: "56",
            },
            {
              measureDate: "06/24",
              highPressure: "138",
              lowPressure: "90",
              pulse: "122",
              bloodOxygen: "72",
              fatigueIndex: "58",
            },
          ],
          score: {
            bloodPressure: {
              highPressure: "138",
              lowPressure: "90",
            },
            pulse: "122",
            bloodOxygen: "72",
            fatigueIndex: "58",
          },
        },
        {
          data: [
            {
              measureDate: "06/24",
              highPressure: "118",
              lowPressure: "88",
              pulse: "100",
              bloodOxygen: "61",
              fatigueIndex: "48",
            },
            {
              measureDate: "06/24",
              highPressure: "120",
              lowPressure: "85",
              pulse: "110",
              bloodOxygen: "62",
              fatigueIndex: "50",
            },
            {
              measureDate: "06/24",
              highPressure: "130",
              lowPressure: "88",
              pulse: "112",
              bloodOxygen: "64",
              fatigueIndex: "52",
            },
            {
              measureDate: "06/24",
              highPressure: "138",
              lowPressure: "92",
              pulse: "115",
              bloodOxygen: "66",
              fatigueIndex: "54",
            },
            {
              measureDate: "06/24",
              highPressure: "132",
              lowPressure: "84",
              pulse: "113",
              bloodOxygen: "64",
              fatigueIndex: "52",
            },
            {
              measureDate: "06/24",
              highPressure: "148",
              lowPressure: "80",
              pulse: "116",
              bloodOxygen: "67",
              fatigueIndex: "54",
            },
            {
              measureDate: "06/24",
              highPressure: "138",
              lowPressure: "90",
              pulse: "120",
              bloodOxygen: "70",
              fatigueIndex: "56",
            },
            {
              measureDate: "06/24",
              highPressure: "138",
              lowPressure: "90",
              pulse: "122",
              bloodOxygen: "72",
              fatigueIndex: "58",
            },
            {
              measureDate: "06/24",
              highPressure: "128",
              lowPressure: "92",
              pulse: "124",
              bloodOxygen: "74",
              fatigueIndex: "62",
            },
          ],
          score: {
            bloodPressure: {
              highPressure: "128",
              lowPressure: "92",
            },
            pulse: "124",
            bloodOxygen: "74",
            fatigueIndex: "62",
          },
        },
        {
          data: [
            {
              measureDate: "06/24",
              highPressure: "120",
              lowPressure: "85",
              pulse: "110",
              bloodOxygen: "62",
              fatigueIndex: "50",
            },
            {
              measureDate: "06/24",
              highPressure: "130",
              lowPressure: "88",
              pulse: "112",
              bloodOxygen: "64",
              fatigueIndex: "52",
            },
            {
              measureDate: "06/24",
              highPressure: "138",
              lowPressure: "92",
              pulse: "115",
              bloodOxygen: "66",
              fatigueIndex: "54",
            },
            {
              measureDate: "06/24",
              highPressure: "132",
              lowPressure: "84",
              pulse: "113",
              bloodOxygen: "64",
              fatigueIndex: "52",
            },
            {
              measureDate: "06/24",
              highPressure: "148",
              lowPressure: "80",
              pulse: "116",
              bloodOxygen: "67",
              fatigueIndex: "54",
            },
            {
              measureDate: "06/24",
              highPressure: "138",
              lowPressure: "90",
              pulse: "120",
              bloodOxygen: "70",
              fatigueIndex: "56",
            },
            {
              measureDate: "06/24",
              highPressure: "138",
              lowPressure: "90",
              pulse: "122",
              bloodOxygen: "72",
              fatigueIndex: "58",
            },
            {
              measureDate: "06/24",
              highPressure: "128",
              lowPressure: "92",
              pulse: "124",
              bloodOxygen: "74",
              fatigueIndex: "62",
            },
            {
              measureDate: "06/24",
              highPressure: "118",
              lowPressure: "88",
              pulse: "126",
              bloodOxygen: "76",
              fatigueIndex: "64",
            },
          ],
          score: {
            bloodPressure: {
              highPressure: "118",
              lowPressure: "88",
            },
            pulse: "126",
            bloodOxygen: "76",
            fatigueIndex: "64",
          },
        },
        {
          data: [
            {
              measureDate: "06/24",
              highPressure: "130",
              lowPressure: "88",
              pulse: "112",
              bloodOxygen: "64",
              fatigueIndex: "52",
            },
            {
              measureDate: "06/24",
              highPressure: "138",
              lowPressure: "92",
              pulse: "115",
              bloodOxygen: "66",
              fatigueIndex: "54",
            },
            {
              measureDate: "06/24",
              highPressure: "132",
              lowPressure: "84",
              pulse: "113",
              bloodOxygen: "64",
              fatigueIndex: "52",
            },
            {
              measureDate: "06/24",
              highPressure: "148",
              lowPressure: "80",
              pulse: "116",
              bloodOxygen: "67",
              fatigueIndex: "54",
            },
            {
              measureDate: "06/24",
              highPressure: "138",
              lowPressure: "90",
              pulse: "120",
              bloodOxygen: "70",
              fatigueIndex: "56",
            },
            {
              measureDate: "06/24",
              highPressure: "138",
              lowPressure: "90",
              pulse: "122",
              bloodOxygen: "72",
              fatigueIndex: "58",
            },
            {
              measureDate: "06/24",
              highPressure: "128",
              lowPressure: "92",
              pulse: "124",
              bloodOxygen: "74",
              fatigueIndex: "62",
            },
            {
              measureDate: "06/24",
              highPressure: "118",
              lowPressure: "88",
              pulse: "126",
              bloodOxygen: "76",
              fatigueIndex: "64",
            },
            {
              measureDate: "06/24",
              highPressure: "120",
              lowPressure: "85",
              pulse: "124",
              bloodOxygen: "74",
              fatigueIndex: "62",
            },
          ],
          score: {
            bloodPressure: {
              highPressure: "120",
              lowPressure: "85",
            },
            pulse: "124",
            bloodOxygen: "74",
            fatigueIndex: "62",
          },
        },
        {
          data: [
            {
              measureDate: "06/24",
              highPressure: "138",
              lowPressure: "92",
              pulse: "115",
              bloodOxygen: "66",
              fatigueIndex: "54",
            },
            {
              measureDate: "06/24",
              highPressure: "132",
              lowPressure: "84",
              pulse: "113",
              bloodOxygen: "64",
              fatigueIndex: "52",
            },
            {
              measureDate: "06/24",
              highPressure: "148",
              lowPressure: "80",
              pulse: "116",
              bloodOxygen: "67",
              fatigueIndex: "54",
            },
            {
              measureDate: "06/24",
              highPressure: "138",
              lowPressure: "90",
              pulse: "120",
              bloodOxygen: "70",
              fatigueIndex: "56",
            },
            {
              measureDate: "06/24",
              highPressure: "138",
              lowPressure: "90",
              pulse: "122",
              bloodOxygen: "72",
              fatigueIndex: "58",
            },
            {
              measureDate: "06/24",
              highPressure: "128",
              lowPressure: "92",
              pulse: "124",
              bloodOxygen: "74",
              fatigueIndex: "62",
            },
            {
              measureDate: "06/24",
              highPressure: "118",
              lowPressure: "88",
              pulse: "126",
              bloodOxygen: "76",
              fatigueIndex: "64",
            },
            {
              measureDate: "06/24",
              highPressure: "120",
              lowPressure: "85",
              pulse: "124",
              bloodOxygen: "74",
              fatigueIndex: "62",
            },
            {
              measureDate: "06/24",
              highPressure: "130",
              lowPressure: "88",
              pulse: "127",
              bloodOxygen: "74",
              fatigueIndex: "64",
            },
          ],
          score: {
            bloodPressure: {
              highPressure: "130",
              lowPressure: "88",
            },
            pulse: "127",
            bloodOxygen: "74",
            fatigueIndex: "64",
          },
        },
        {
          data: [
            {
              measureDate: "06/24",
              highPressure: "132",
              lowPressure: "84",
              pulse: "113",
              bloodOxygen: "64",
              fatigueIndex: "52",
            },
            {
              measureDate: "06/24",
              highPressure: "148",
              lowPressure: "80",
              pulse: "116",
              bloodOxygen: "67",
              fatigueIndex: "54",
            },
            {
              measureDate: "06/24",
              highPressure: "138",
              lowPressure: "90",
              pulse: "120",
              bloodOxygen: "70",
              fatigueIndex: "56",
            },
            {
              measureDate: "06/24",
              highPressure: "138",
              lowPressure: "90",
              pulse: "122",
              bloodOxygen: "72",
              fatigueIndex: "58",
            },
            {
              measureDate: "06/24",
              highPressure: "128",
              lowPressure: "92",
              pulse: "124",
              bloodOxygen: "74",
              fatigueIndex: "62",
            },
            {
              measureDate: "06/24",
              highPressure: "118",
              lowPressure: "88",
              pulse: "126",
              bloodOxygen: "76",
              fatigueIndex: "64",
            },
            {
              measureDate: "06/24",
              highPressure: "120",
              lowPressure: "85",
              pulse: "124",
              bloodOxygen: "74",
              fatigueIndex: "62",
            },
            {
              measureDate: "06/24",
              highPressure: "130",
              lowPressure: "88",
              pulse: "127",
              bloodOxygen: "74",
              fatigueIndex: "64",
            },
            {
              measureDate: "06/24",
              highPressure: "138",
              lowPressure: "92",
              pulse: "129",
              bloodOxygen: "75",
              fatigueIndex: "65",
            },
          ],
          score: {
            bloodPressure: {
              highPressure: "138",
              lowPressure: "92",
            },
            pulse: "129",
            bloodOxygen: "75",
            fatigueIndex: "65",
          },
        },
        {
          data: [
            {
              measureDate: "06/24",
              highPressure: "148",
              lowPressure: "80",
              pulse: "116",
              bloodOxygen: "67",
              fatigueIndex: "54",
            },
            {
              measureDate: "06/24",
              highPressure: "138",
              lowPressure: "90",
              pulse: "120",
              bloodOxygen: "70",
              fatigueIndex: "56",
            },
            {
              measureDate: "06/24",
              highPressure: "138",
              lowPressure: "90",
              pulse: "122",
              bloodOxygen: "72",
              fatigueIndex: "58",
            },
            {
              measureDate: "06/24",
              highPressure: "128",
              lowPressure: "92",
              pulse: "124",
              bloodOxygen: "74",
              fatigueIndex: "62",
            },
            {
              measureDate: "06/24",
              highPressure: "118",
              lowPressure: "88",
              pulse: "126",
              bloodOxygen: "76",
              fatigueIndex: "64",
            },
            {
              measureDate: "06/24",
              highPressure: "120",
              lowPressure: "85",
              pulse: "124",
              bloodOxygen: "74",
              fatigueIndex: "62",
            },
            {
              measureDate: "06/24",
              highPressure: "130",
              lowPressure: "88",
              pulse: "127",
              bloodOxygen: "74",
              fatigueIndex: "64",
            },
            {
              measureDate: "06/24",
              highPressure: "138",
              lowPressure: "92",
              pulse: "129",
              bloodOxygen: "75",
              fatigueIndex: "65",
            },
            {
              measureDate: "06/24",
              highPressure: "138",
              lowPressure: "92",
              pulse: "130",
              bloodOxygen: "75",
              fatigueIndex: "65",
            },
          ],
          score: {
            bloodPressure: {
              highPressure: "138",
              lowPressure: "92",
            },
            pulse: "130",
            bloodOxygen: "75",
            fatigueIndex: "65",
          },
        },
      ],

      wangData: [
        {
          data: [
            {
              measureDate: "06/24",
              highPressure: "132",
              lowPressure: "84",
              pulse: "113",
              bloodOxygen: "64",
              fatigueIndex: "82",
            },
            {
              measureDate: "06/24",
              highPressure: "148",
              lowPressure: "80",
              pulse: "116",
              bloodOxygen: "67",
              fatigueIndex: "84",
            },
            {
              measureDate: "06/24",
              highPressure: "138",
              lowPressure: "90",
              pulse: "120",
              bloodOxygen: "70",
              fatigueIndex: "86",
            },
            {
              measureDate: "06/24",
              highPressure: "138",
              lowPressure: "90",
              pulse: "122",
              bloodOxygen: "72",
              fatigueIndex: "88",
            },
            {
              measureDate: "06/24",
              highPressure: "128",
              lowPressure: "92",
              pulse: "124",
              bloodOxygen: "74",
              fatigueIndex: "82",
            },
            {
              measureDate: "06/24",
              highPressure: "118",
              lowPressure: "88",
              pulse: "126",
              bloodOxygen: "76",
              fatigueIndex: "84",
            },
            {
              measureDate: "06/24",
              highPressure: "120",
              lowPressure: "85",
              pulse: "124",
              bloodOxygen: "74",
              fatigueIndex: "82",
            },
            {
              measureDate: "06/24",
              highPressure: "130",
              lowPressure: "88",
              pulse: "127",
              bloodOxygen: "74",
              fatigueIndex: "84",
            },
            {
              measureDate: "06/24",
              highPressure: "138",
              lowPressure: "92",
              pulse: "129",
              bloodOxygen: "75",
              fatigueIndex: "85",
            },
          ],
          score: {
            bloodPressure: {
              highPressure: "138",
              lowPressure: "92",
            },
            pulse: "129",
            bloodOxygen: "75",
            fatigueIndex: "85",
          },
        },
        {
          data: [
            {
              measureDate: "06/24",
              highPressure: "148",
              lowPressure: "80",
              pulse: "116",
              bloodOxygen: "67",
              fatigueIndex: "84",
            },
            {
              measureDate: "06/24",
              highPressure: "138",
              lowPressure: "90",
              pulse: "120",
              bloodOxygen: "70",
              fatigueIndex: "86",
            },
            {
              measureDate: "06/24",
              highPressure: "138",
              lowPressure: "90",
              pulse: "122",
              bloodOxygen: "72",
              fatigueIndex: "88",
            },
            {
              measureDate: "06/24",
              highPressure: "128",
              lowPressure: "92",
              pulse: "124",
              bloodOxygen: "74",
              fatigueIndex: "82",
            },
            {
              measureDate: "06/24",
              highPressure: "118",
              lowPressure: "88",
              pulse: "126",
              bloodOxygen: "76",
              fatigueIndex: "84",
            },
            {
              measureDate: "06/24",
              highPressure: "120",
              lowPressure: "85",
              pulse: "124",
              bloodOxygen: "74",
              fatigueIndex: "82",
            },
            {
              measureDate: "06/24",
              highPressure: "130",
              lowPressure: "88",
              pulse: "127",
              bloodOxygen: "74",
              fatigueIndex: "84",
            },
            {
              measureDate: "06/24",
              highPressure: "138",
              lowPressure: "92",
              pulse: "129",
              bloodOxygen: "75",
              fatigueIndex: "85",
            },
            {
              measureDate: "06/24",
              highPressure: "136",
              lowPressure: "90",
              pulse: "128",
              bloodOxygen: "70",
              fatigueIndex: "82",
            },
          ],
          score: {
            bloodPressure: {
              highPressure: "136",
              lowPressure: "90",
            },
            pulse: "128",
            bloodOxygen: "70",
            fatigueIndex: "82",
          },
        },
        {
          data: [
            {
              measureDate: "06/24",
              highPressure: "138",
              lowPressure: "90",
              pulse: "120",
              bloodOxygen: "70",
              fatigueIndex: "86",
            },
            {
              measureDate: "06/24",
              highPressure: "138",
              lowPressure: "90",
              pulse: "122",
              bloodOxygen: "72",
              fatigueIndex: "88",
            },
            {
              measureDate: "06/24",
              highPressure: "128",
              lowPressure: "92",
              pulse: "124",
              bloodOxygen: "74",
              fatigueIndex: "82",
            },
            {
              measureDate: "06/24",
              highPressure: "118",
              lowPressure: "88",
              pulse: "126",
              bloodOxygen: "76",
              fatigueIndex: "84",
            },
            {
              measureDate: "06/24",
              highPressure: "120",
              lowPressure: "85",
              pulse: "124",
              bloodOxygen: "74",
              fatigueIndex: "82",
            },
            {
              measureDate: "06/24",
              highPressure: "130",
              lowPressure: "88",
              pulse: "127",
              bloodOxygen: "74",
              fatigueIndex: "84",
            },
            {
              measureDate: "06/24",
              highPressure: "138",
              lowPressure: "92",
              pulse: "129",
              bloodOxygen: "75",
              fatigueIndex: "85",
            },
            {
              measureDate: "06/24",
              highPressure: "138",
              lowPressure: "92",
              pulse: "130",
              bloodOxygen: "75",
              fatigueIndex: "85",
            },
            {
              measureDate: "06/24",
              highPressure: "148",
              lowPressure: "80",
              pulse: "116",
              bloodOxygen: "67",
              fatigueIndex: "84",
            },
          ],
          score: {
            bloodPressure: {
              highPressure: "148",
              lowPressure: "80",
            },
            pulse: "116",
            bloodOxygen: "67",
            fatigueIndex: "84",
          },
        },
        {
          data: [
            {
              measureDate: "06/24",
              highPressure: "138",
              lowPressure: "90",
              pulse: "122",
              bloodOxygen: "72",
              fatigueIndex: "88",
            },
            {
              measureDate: "06/24",
              highPressure: "128",
              lowPressure: "92",
              pulse: "124",
              bloodOxygen: "74",
              fatigueIndex: "82",
            },
            {
              measureDate: "06/24",
              highPressure: "118",
              lowPressure: "88",
              pulse: "126",
              bloodOxygen: "76",
              fatigueIndex: "84",
            },
            {
              measureDate: "06/24",
              highPressure: "120",
              lowPressure: "85",
              pulse: "124",
              bloodOxygen: "74",
              fatigueIndex: "82",
            },
            {
              measureDate: "06/24",
              highPressure: "130",
              lowPressure: "88",
              pulse: "127",
              bloodOxygen: "74",
              fatigueIndex: "84",
            },
            {
              measureDate: "06/24",
              highPressure: "138",
              lowPressure: "92",
              pulse: "129",
              bloodOxygen: "75",
              fatigueIndex: "85",
            },
            {
              measureDate: "06/24",
              highPressure: "138",
              lowPressure: "92",
              pulse: "130",
              bloodOxygen: "75",
              fatigueIndex: "85",
            },
            {
              measureDate: "06/24",
              highPressure: "148",
              lowPressure: "80",
              pulse: "116",
              bloodOxygen: "67",
              fatigueIndex: "84",
            },
            {
              measureDate: "06/24",
              highPressure: "140",
              lowPressure: "80",
              pulse: "121",
              bloodOxygen: "70",
              fatigueIndex: "86",
            },
          ],
          score: {
            bloodPressure: {
              highPressure: "140",
              lowPressure: "80",
            },
            pulse: "121",
            bloodOxygen: "70",
            fatigueIndex: "86",
          },
        },
        {
          data: [
            {
              measureDate: "06/24",
              highPressure: "128",
              lowPressure: "92",
              pulse: "124",
              bloodOxygen: "74",
              fatigueIndex: "82",
            },
            {
              measureDate: "06/24",
              highPressure: "118",
              lowPressure: "88",
              pulse: "126",
              bloodOxygen: "76",
              fatigueIndex: "84",
            },
            {
              measureDate: "06/24",
              highPressure: "120",
              lowPressure: "85",
              pulse: "124",
              bloodOxygen: "74",
              fatigueIndex: "82",
            },
            {
              measureDate: "06/24",
              highPressure: "130",
              lowPressure: "88",
              pulse: "127",
              bloodOxygen: "74",
              fatigueIndex: "84",
            },
            {
              measureDate: "06/24",
              highPressure: "138",
              lowPressure: "92",
              pulse: "129",
              bloodOxygen: "75",
              fatigueIndex: "85",
            },
            {
              measureDate: "06/24",
              highPressure: "138",
              lowPressure: "92",
              pulse: "130",
              bloodOxygen: "75",
              fatigueIndex: "85",
            },
            {
              measureDate: "06/24",
              highPressure: "148",
              lowPressure: "80",
              pulse: "116",
              bloodOxygen: "67",
              fatigueIndex: "84",
            },
            {
              measureDate: "06/24",
              highPressure: "140",
              lowPressure: "80",
              pulse: "121",
              bloodOxygen: "70",
              fatigueIndex: "86",
            },
            {
              measureDate: "06/24",
              highPressure: "136",
              lowPressure: "75",
              pulse: "127",
              bloodOxygen: "72",
              fatigueIndex: "88",
            },
          ],
          score: {
            bloodPressure: {
              highPressure: "136",
              lowPressure: "75",
            },
            pulse: "127",
            bloodOxygen: "72",
            fatigueIndex: "88",
          },
        },
        {
          data: [
            {
              measureDate: "06/24",
              highPressure: "118",
              lowPressure: "88",
              pulse: "126",
              bloodOxygen: "76",
              fatigueIndex: "84",
            },
            {
              measureDate: "06/24",
              highPressure: "120",
              lowPressure: "85",
              pulse: "124",
              bloodOxygen: "74",
              fatigueIndex: "82",
            },
            {
              measureDate: "06/24",
              highPressure: "130",
              lowPressure: "88",
              pulse: "127",
              bloodOxygen: "74",
              fatigueIndex: "84",
            },
            {
              measureDate: "06/24",
              highPressure: "138",
              lowPressure: "92",
              pulse: "129",
              bloodOxygen: "75",
              fatigueIndex: "85",
            },
            {
              measureDate: "06/24",
              highPressure: "138",
              lowPressure: "92",
              pulse: "130",
              bloodOxygen: "75",
              fatigueIndex: "85",
            },
            {
              measureDate: "06/24",
              highPressure: "148",
              lowPressure: "80",
              pulse: "116",
              bloodOxygen: "67",
              fatigueIndex: "84",
            },
            {
              measureDate: "06/24",
              highPressure: "140",
              lowPressure: "80",
              pulse: "121",
              bloodOxygen: "70",
              fatigueIndex: "86",
            },
            {
              measureDate: "06/24",
              highPressure: "136",
              lowPressure: "75",
              pulse: "127",
              bloodOxygen: "72",
              fatigueIndex: "88",
            },
            {
              measureDate: "06/24",
              highPressure: "128",
              lowPressure: "92",
              pulse: "124",
              bloodOxygen: "74",
              fatigueIndex: "82",
            },
          ],
          score: {
            bloodPressure: {
              highPressure: "128",
              lowPressure: "92",
            },
            pulse: "124",
            bloodOxygen: "74",
            fatigueIndex: "82",
          },
        },
        {
          data: [
            {
              measureDate: "06/24",
              highPressure: "120",
              lowPressure: "85",
              pulse: "124",
              bloodOxygen: "74",
              fatigueIndex: "82",
            },
            {
              measureDate: "06/24",
              highPressure: "130",
              lowPressure: "88",
              pulse: "127",
              bloodOxygen: "74",
              fatigueIndex: "84",
            },
            {
              measureDate: "06/24",
              highPressure: "138",
              lowPressure: "92",
              pulse: "129",
              bloodOxygen: "75",
              fatigueIndex: "85",
            },
            {
              measureDate: "06/24",
              highPressure: "138",
              lowPressure: "92",
              pulse: "130",
              bloodOxygen: "75",
              fatigueIndex: "85",
            },
            {
              measureDate: "06/24",
              highPressure: "148",
              lowPressure: "80",
              pulse: "116",
              bloodOxygen: "67",
              fatigueIndex: "84",
            },
            {
              measureDate: "06/24",
              highPressure: "140",
              lowPressure: "80",
              pulse: "121",
              bloodOxygen: "70",
              fatigueIndex: "86",
            },
            {
              measureDate: "06/24",
              highPressure: "136",
              lowPressure: "75",
              pulse: "127",
              bloodOxygen: "72",
              fatigueIndex: "88",
            },
            {
              measureDate: "06/24",
              highPressure: "128",
              lowPressure: "92",
              pulse: "124",
              bloodOxygen: "74",
              fatigueIndex: "82",
            },
            {
              measureDate: "06/24",
              highPressure: "118",
              lowPressure: "88",
              pulse: "126",
              bloodOxygen: "78",
              fatigueIndex: "86",
            },
          ],
          score: {
            bloodPressure: {
              highPressure: "118",
              lowPressure: "88",
            },
            pulse: "126",
            bloodOxygen: "78",
            fatigueIndex: "86",
          },
        },
        {
          data: [
            {
              measureDate: "06/24",
              highPressure: "130",
              lowPressure: "88",
              pulse: "127",
              bloodOxygen: "74",
              fatigueIndex: "84",
            },
            {
              measureDate: "06/24",
              highPressure: "138",
              lowPressure: "92",
              pulse: "129",
              bloodOxygen: "75",
              fatigueIndex: "85",
            },
            {
              measureDate: "06/24",
              highPressure: "138",
              lowPressure: "92",
              pulse: "130",
              bloodOxygen: "75",
              fatigueIndex: "85",
            },
            {
              measureDate: "06/24",
              highPressure: "148",
              lowPressure: "80",
              pulse: "116",
              bloodOxygen: "67",
              fatigueIndex: "84",
            },
            {
              measureDate: "06/24",
              highPressure: "140",
              lowPressure: "80",
              pulse: "121",
              bloodOxygen: "70",
              fatigueIndex: "86",
            },
            {
              measureDate: "06/24",
              highPressure: "136",
              lowPressure: "75",
              pulse: "127",
              bloodOxygen: "72",
              fatigueIndex: "88",
            },
            {
              measureDate: "06/24",
              highPressure: "128",
              lowPressure: "92",
              pulse: "124",
              bloodOxygen: "74",
              fatigueIndex: "82",
            },
            {
              measureDate: "06/24",
              highPressure: "118",
              lowPressure: "88",
              pulse: "126",
              bloodOxygen: "78",
              fatigueIndex: "86",
            },
            {
              measureDate: "06/24",
              highPressure: "120",
              lowPressure: "85",
              pulse: "124",
              bloodOxygen: "74",
              fatigueIndex: "82",
            },
          ],
          score: {
            bloodPressure: {
              highPressure: "120",
              lowPressure: "85",
            },
            pulse: "124",
            bloodOxygen: "74",
            fatigueIndex: "82",
          },
        },
        {
          data: [
            {
              measureDate: "06/24",
              highPressure: "138",
              lowPressure: "92",
              pulse: "129",
              bloodOxygen: "75",
              fatigueIndex: "85",
            },
            {
              measureDate: "06/24",
              highPressure: "138",
              lowPressure: "92",
              pulse: "130",
              bloodOxygen: "75",
              fatigueIndex: "85",
            },
            {
              measureDate: "06/24",
              highPressure: "148",
              lowPressure: "80",
              pulse: "116",
              bloodOxygen: "67",
              fatigueIndex: "84",
            },
            {
              measureDate: "06/24",
              highPressure: "140",
              lowPressure: "80",
              pulse: "121",
              bloodOxygen: "70",
              fatigueIndex: "86",
            },
            {
              measureDate: "06/24",
              highPressure: "136",
              lowPressure: "75",
              pulse: "127",
              bloodOxygen: "72",
              fatigueIndex: "88",
            },
            {
              measureDate: "06/24",
              highPressure: "128",
              lowPressure: "92",
              pulse: "124",
              bloodOxygen: "74",
              fatigueIndex: "82",
            },
            {
              measureDate: "06/24",
              highPressure: "118",
              lowPressure: "88",
              pulse: "126",
              bloodOxygen: "78",
              fatigueIndex: "86",
            },
            {
              measureDate: "06/24",
              highPressure: "120",
              lowPressure: "85",
              pulse: "124",
              bloodOxygen: "74",
              fatigueIndex: "82",
            },
            {
              measureDate: "06/24",
              highPressure: "130",
              lowPressure: "88",
              pulse: "122",
              bloodOxygen: "74",
              fatigueIndex: "80",
            },
          ],
          score: {
            bloodPressure: {
              highPressure: "130",
              lowPressure: "88",
            },
            pulse: "122",
            bloodOxygen: "74",
            fatigueIndex: "80",
          },
        },
        {
          data: [
            {
              measureDate: "06/24",
              highPressure: "138",
              lowPressure: "92",
              pulse: "130",
              bloodOxygen: "75",
              fatigueIndex: "85",
            },
            {
              measureDate: "06/24",
              highPressure: "148",
              lowPressure: "80",
              pulse: "116",
              bloodOxygen: "67",
              fatigueIndex: "84",
            },
            {
              measureDate: "06/24",
              highPressure: "140",
              lowPressure: "80",
              pulse: "121",
              bloodOxygen: "70",
              fatigueIndex: "86",
            },
            {
              measureDate: "06/24",
              highPressure: "136",
              lowPressure: "75",
              pulse: "127",
              bloodOxygen: "72",
              fatigueIndex: "88",
            },
            {
              measureDate: "06/24",
              highPressure: "128",
              lowPressure: "92",
              pulse: "124",
              bloodOxygen: "74",
              fatigueIndex: "82",
            },
            {
              measureDate: "06/24",
              highPressure: "118",
              lowPressure: "88",
              pulse: "126",
              bloodOxygen: "78",
              fatigueIndex: "86",
            },
            {
              measureDate: "06/24",
              highPressure: "120",
              lowPressure: "85",
              pulse: "124",
              bloodOxygen: "74",
              fatigueIndex: "82",
            },
            {
              measureDate: "06/24",
              highPressure: "130",
              lowPressure: "88",
              pulse: "122",
              bloodOxygen: "74",
              fatigueIndex: "80",
            },
            {
              measureDate: "06/24",
              highPressure: "138",
              lowPressure: "92",
              pulse: "126",
              bloodOxygen: "75",
              fatigueIndex: "83",
            },
          ],
          score: {
            bloodPressure: {
              highPressure: "138",
              lowPressure: "92",
            },
            pulse: "126",
            bloodOxygen: "75",
            fatigueIndex: "83",
          },
        },
        {
          data: [
            {
              measureDate: "06/24",
              highPressure: "148",
              lowPressure: "80",
              pulse: "116",
              bloodOxygen: "67",
              fatigueIndex: "84",
            },
            {
              measureDate: "06/24",
              highPressure: "140",
              lowPressure: "80",
              pulse: "121",
              bloodOxygen: "70",
              fatigueIndex: "86",
            },
            {
              measureDate: "06/24",
              highPressure: "136",
              lowPressure: "75",
              pulse: "127",
              bloodOxygen: "72",
              fatigueIndex: "88",
            },
            {
              measureDate: "06/24",
              highPressure: "128",
              lowPressure: "92",
              pulse: "124",
              bloodOxygen: "74",
              fatigueIndex: "82",
            },
            {
              measureDate: "06/24",
              highPressure: "118",
              lowPressure: "88",
              pulse: "126",
              bloodOxygen: "78",
              fatigueIndex: "86",
            },
            {
              measureDate: "06/24",
              highPressure: "120",
              lowPressure: "85",
              pulse: "124",
              bloodOxygen: "74",
              fatigueIndex: "82",
            },
            {
              measureDate: "06/24",
              highPressure: "130",
              lowPressure: "88",
              pulse: "122",
              bloodOxygen: "74",
              fatigueIndex: "80",
            },
            {
              measureDate: "06/24",
              highPressure: "138",
              lowPressure: "92",
              pulse: "126",
              bloodOxygen: "75",
              fatigueIndex: "83",
            },
            {
              measureDate: "06/24",
              highPressure: "138",
              lowPressure: "92",
              pulse: "130",
              bloodOxygen: "75",
              fatigueIndex: "85",
            },
          ],
          score: {
            bloodPressure: {
              highPressure: "138",
              lowPressure: "92",
            },
            pulse: "130",
            bloodOxygen: "75",
            fatigueIndex: "85",
          },
        },
        {
          data: [
            {
              measureDate: "06/24",
              highPressure: "140",
              lowPressure: "80",
              pulse: "121",
              bloodOxygen: "70",
              fatigueIndex: "86",
            },
            {
              measureDate: "06/24",
              highPressure: "136",
              lowPressure: "75",
              pulse: "127",
              bloodOxygen: "72",
              fatigueIndex: "88",
            },
            {
              measureDate: "06/24",
              highPressure: "128",
              lowPressure: "92",
              pulse: "124",
              bloodOxygen: "74",
              fatigueIndex: "82",
            },
            {
              measureDate: "06/24",
              highPressure: "118",
              lowPressure: "88",
              pulse: "126",
              bloodOxygen: "78",
              fatigueIndex: "86",
            },
            {
              measureDate: "06/24",
              highPressure: "120",
              lowPressure: "85",
              pulse: "124",
              bloodOxygen: "74",
              fatigueIndex: "82",
            },
            {
              measureDate: "06/24",
              highPressure: "130",
              lowPressure: "88",
              pulse: "122",
              bloodOxygen: "74",
              fatigueIndex: "80",
            },
            {
              measureDate: "06/24",
              highPressure: "138",
              lowPressure: "92",
              pulse: "126",
              bloodOxygen: "75",
              fatigueIndex: "83",
            },
            {
              measureDate: "06/24",
              highPressure: "138",
              lowPressure: "92",
              pulse: "130",
              bloodOxygen: "75",
              fatigueIndex: "85",
            },
            {
              measureDate: "06/24",
              highPressure: "138",
              lowPressure: "80",
              pulse: "120",
              bloodOxygen: "67",
              fatigueIndex: "80",
            },
          ],
          score: {
            bloodPressure: {
              highPressure: "138",
              lowPressure: "80",
            },
            pulse: "120",
            bloodOxygen: "67",
            fatigueIndex: "80",
          },
        },
        {
          data: [
            {
              measureDate: "06/24",
              highPressure: "136",
              lowPressure: "75",
              pulse: "127",
              bloodOxygen: "72",
              fatigueIndex: "88",
            },
            {
              measureDate: "06/24",
              highPressure: "128",
              lowPressure: "92",
              pulse: "124",
              bloodOxygen: "74",
              fatigueIndex: "82",
            },
            {
              measureDate: "06/24",
              highPressure: "118",
              lowPressure: "88",
              pulse: "126",
              bloodOxygen: "78",
              fatigueIndex: "86",
            },
            {
              measureDate: "06/24",
              highPressure: "120",
              lowPressure: "85",
              pulse: "124",
              bloodOxygen: "74",
              fatigueIndex: "82",
            },
            {
              measureDate: "06/24",
              highPressure: "130",
              lowPressure: "88",
              pulse: "122",
              bloodOxygen: "74",
              fatigueIndex: "80",
            },
            {
              measureDate: "06/24",
              highPressure: "138",
              lowPressure: "92",
              pulse: "126",
              bloodOxygen: "75",
              fatigueIndex: "83",
            },
            {
              measureDate: "06/24",
              highPressure: "138",
              lowPressure: "92",
              pulse: "130",
              bloodOxygen: "75",
              fatigueIndex: "85",
            },
            {
              measureDate: "06/24",
              highPressure: "138",
              lowPressure: "80",
              pulse: "120",
              bloodOxygen: "67",
              fatigueIndex: "80",
            },
            {
              measureDate: "06/24",
              highPressure: "132",
              lowPressure: "82",
              pulse: "118",
              bloodOxygen: "70",
              fatigueIndex: "81",
            },
          ],
          score: {
            bloodPressure: {
              highPressure: "132",
              lowPressure: "82",
            },
            pulse: "118",
            bloodOxygen: "70",
            fatigueIndex: "81",
          },
        },
      ],
      timer: null,

      dataIndex: 0,
    };
  },
  created() {
    this.browserSizeChange();
    if (sessionStorage.getItem("DadHaierUserId")) {
      this.idList.DadHaierUserId = sessionStorage.getItem("DadHaierUserId");
      this.idList.DadOffUserId = sessionStorage.getItem("DadOffUserId");
      this.idList.MumHaierUserId = sessionStorage.getItem("MumHaierUserId");
      this.idList.MumOffUserId = sessionStorage.getItem("MumOffUserId");
    }
    getKey(this.idList.DadHaierUserId).then((res) => {
      console.log("res", res);
      sessionStorage.setItem("key", res.data);
    });
    getKey(this.idList.DadOffUserId).then((res) => {
      console.log("res", res);
      sessionStorage.setItem("offkey", res.data);
    });
  },
  mounted() {
    window.onresize = () => {
      this.browserSizeChange();
    };
    let chartDom1 = document.getElementById("line_chart1");
    lineChart1 = echarts.init(chartDom1);
    let chartDom2 = document.getElementById("line_chart2");
    lineChart2 = echarts.init(chartDom2);
    let chartDom5 = document.getElementById("line_chart5");
    lineChart5 = echarts.init(chartDom5);
    let chartDom6 = document.getElementById("line_chart6");
    lineChart6 = echarts.init(chartDom6);
  },
  methods: {
    showTab() {
      this.show = this.showStatus;
    },
    showIndex() {
      this.show = "normal";
    },
    updateId() {
      this.dialogVisible = true;
    },
    userChange(value, index) {
      console.log("ces", value, index);
      let _that = this;
      _that.userSelect = {
        user1: null,
        user2: null,
        user3: null,
        user4: null,
        user5: null,
        user6: null,
      };
      _that.userSelect["user" + index] = value;
      clearInterval(_that.timer);

      _that.initData(0, value);
      _that.dataIndex = 1;

      _that.timer = setInterval(function () {
        if (_that.dataIndex === _that.liuData.length) {
          clearInterval(_that.timer);
        } else {
          _that.initData(_that.dataIndex, value);
          _that.dataIndex++;
        }
      }, 13000);
    },
    initData(index, val) {
      let deflutoption = {
        grid: {
          left: "0%",
          width: "99%",
        },

        xAxis: {
          type: "category",
          data: ["01/12", "02/12", "03/12", "04/12", "05/12", "06/12", "07/12"],
          axisLabel: {
            //x轴文字的配置
            show: false,
            textStyle: {
              color: "#fff",
            },
          },
        },
        yAxis: {
          type: "value",
          show: false,
        },
        series: [
          {
            data: [0, 0, 0, 0, 0, 0, 0],
            type: "line",
            smooth: true,
            symbolSize: 6,
            itemStyle: {
              normal: {
                color: "white", //折线点的颜色
              },
            },
            lineStyle: {
              color: "#FCFE54",
            },
          },
          {
            data: [0, 0, 0, 0, 0, 0, 0],
            type: "line",
            smooth: true,
            symbolSize: 6,
            itemStyle: {
              normal: {
                color: "white", //折线点的颜色
              },
            },

            lineStyle: {
              color: "#FCFE54",
            },
          },
        ],
      };
      let deflutoption1 = {
        grid: {
          left: "0%",
          width: "99%",
        },

        xAxis: {
          type: "category",
          data: ["01/12", "02/12", "03/12", "04/12", "05/12", "06/12", "07/12"],
          axisLabel: {
            //x轴文字的配置
            show: false,
            textStyle: {
              color: "#fff",
            },
          },
        },
        yAxis: {
          type: "value",
          show: false,
        },
        series: [
          {
            data: [0, 0, 0, 0, 0, 0, 0],
            type: "line",
            smooth: true,
            symbolSize: 6,
            itemStyle: {
              normal: {
                color: "white", //折线点的颜色
              },
            },
            lineStyle: {
              color: "#FCFE54",
            },
          },
        ],
      };
      let localData = val === 1 ? this.liuData : this.wangData;

      let idList;
      if (this.idType === "dad") {
        idList = {
          offUserId: this.idList.DadOffUserId,
          haierUserId: this.idList.DadHaierUserId,
        };
      } else {
        idList = {
          offUserId: this.idList.MumOffUserId,
          haierUserId: this.idList.MumHaierUserId,
        };
      }
      getBloodPressureAvg(idList.offUserId).then((res) => {
        console.log(res, deflutoption, deflutoption1);
        // let data = res.data.data[res.data.data.length - 1];
        let dateArr = [];
        let heightArr = [];
        let lowArr = [];
        let xinlvArr = [];
        console.log(localData[index]);
        localData[index].data.forEach((item) => {
          dateArr.push(item.measureDate);
          heightArr.push(item.highPressure);
          lowArr.push(item.lowPressure);
          xinlvArr.push(item.pulse);
        });
        this.option.series[0].data = heightArr;
        this.option.series[1].data = lowArr;
        this.option.xAxis.data = dateArr;
        this.option1.series[0].data = xinlvArr;
        this.option1.xAxis.data = dateArr;
        lineChart1.setOption(this.option);
        lineChart2.setOption(this.option1);
      });
      getBloodOxygenAvg(idList.haierUserId).then((res) => {
        console.log(res);
        let dateArr = [];
        let xueyangArr = [];
        localData[index].data.forEach((item) => {
          dateArr.push(item.measureDate);
          xueyangArr.push(item.bloodOxygen);
        });
        this.option1.series[0].data = xueyangArr;
        this.option1.xAxis.data = dateArr;
        lineChart5.setOption(this.option1);
      });
      getJinFanAvg(idList.haierUserId).then((res) => {
        console.log(res);
        let dateArr = [];
        let pilaoArr = [];
        localData[index].data.forEach((item) => {
          dateArr.push(item.measureDate);
          pilaoArr.push(item.fatigueIndex);
        });
        this.option1.series[0].data = pilaoArr;
        this.option1.xAxis.data = dateArr;
        lineChart6.setOption(this.option1);
      });
      getScore(idList).then((res) => {
        console.log(res);
        this.showStatus = "normal";
        let data = localData[index].score;
        this.gaoyaNum = data.bloodPressure.highPressure;
        this.diyaNum = data.bloodPressure.lowPressure;
        this.xinlvNum = data.pulse;

        this.niaosuanNum = data.bloodOxygen;

        this.pilaoNum = data.fatigueIndex;
        this.$refs["gaoyaEl"].style.left = this.gaoyaNum - 50 + "%";
        this.$refs["gaoyaEl"].style.display = "block";
        this.$refs["diyaEl"].style.left = this.diyaNum - 20 + "%";
        this.$refs["diyaEl"].style.display = "block";
        this.$refs["xinlvEl"].style.left = this.xinlvNum - 40 + "%";
        this.$refs["xinlvEl"].style.display = "block";
        this.$refs["niaosuanEl"].style.left = this.niaosuanNum - 5 + "%";
        this.$refs["niaosuanEl"].style.display = "block";
        this.$refs["pilaoEl"].style.left = this.pilaoNum - 5 + "%";
        this.$refs["pilaoEl"].style.display = "block";
      });
    },

    optionChange() {
      sessionStorage.setItem("DadHaierUserId", this.idList.DadHaierUserId);
      sessionStorage.setItem("DadOffUserId", this.idList.DadOffUserId);
      sessionStorage.setItem("MumHaierUserId", this.idList.MumHaierUserId);
      sessionStorage.setItem("MumOffUserId", this.idList.MumOffUserId);
      let spd = this.idList.speed * 1000;
      if (this.idList.type === 1) {
        clearInterval(this.timer);
      } else {
        if (this.timer === null) {
          this.timer = setInterval(this.initData, spd);
          console.log(this.timer);
        } else {
          clearInterval(this.timer);
          console.log(this.timer, "timer");
          this.timer = setInterval(this.initData, spd);
        }
      }
      if (this.idType === "dad") {
        getKey(this.idList.DadHaierUserId).then((res) => {
          console.log("res", res);
          sessionStorage.setItem("key", res.data);
        });
        getKey(this.idList.DadOffUserId).then((res) => {
          console.log("res", res);
          sessionStorage.setItem("offkey", res.data);
        });
      } else {
        getKey(this.idList.MumHaierUserId).then((res) => {
          console.log("res", res);
          sessionStorage.setItem("key", res.data);
        });
        getKey(this.idList.MumOffUserId).then((res) => {
          console.log("res", res);
          sessionStorage.setItem("offkey", res.data);
        });
      }

      this.dialogVisible = false;
    },
    tabChange(val) {
      this.tabList.forEach((item, index) => {
        if (val == index) {
          item.isActive = true;
          this.idType = item.value;
          console.log(this.idType, "idType");
        } else {
          item.isActive = false;
        }
      });
      if (this.idType === "dad") {
        getKey(this.idList.DadHaierUserId).then((res) => {
          console.log("res", res);
          sessionStorage.setItem("key", res.data);
          getKey(this.idList.DadOffUserId).then((res) => {
            console.log("res", res);
            sessionStorage.setItem("offkey", res.data);
            this.initData();
          });
        });
      } else {
        getKey(this.idList.MumHaierUserId).then((res) => {
          console.log("res", res);
          sessionStorage.setItem("key", res.data);
          getKey(this.idList.MumOffUserId).then((res) => {
            console.log("res", res);
            sessionStorage.setItem("offkey", res.data);
            this.initData();
          });
        });
      }
    },
    browserSizeChange() {
      let bodyEl = document.getElementsByTagName("body");
      console.log(bodyEl, window.innerHeight);
      const h = window.innerHeight / bodyEl[0].clientHeight;
      console.log(h);
      document.body.style.transform = "scale(" + h + "," + h + ")";
    },
  },
  components: {},
};
</script>

<style>
@font-face {
  font-family: "LanTingYaHei";
  src: url("./assets/FZLTXHK.TTF");
}
#app {
  width: 100%;
  height: 100%;
  background-image: url(./assets/bg.jpg);
  background-size: 100% 100%;
  position: relative;
  z-index: 1;
  overflow: hidden;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
}
#app .header {
  /* background-image: url(./assets/title.png); */
  width: 468px;
  height: 58px;
  background-size: 100% 100%;
  margin: 36px auto;
}
#app .border {
  display: flex;
  padding-left: 26px;
  padding-right: 27px;
  justify-content: space-between;
}
#app .border .border_left {
  width: 248px;
  height: 879px;
  background-image: url(./assets/border-3.png);
  background-size: 100% 100%;
}
#app .border .border_left img {
  width: 101px;
  height: 30px;
  margin-left: 118px;
}
#app .border .border_right {
  width: 259px;
  height: 879px;
  background-image: url(./assets/border-4.png);
  background-size: 100% 100%;
}
#app .border .border_right .tab {
  width: 143px;
  height: 28px;
  display: flex;
  background: white;
  color: #5fa5ef;
  font-size: 18px;
  text-align: center;
  border-radius: 13px;
  line-height: 26px;
  cursor: pointer;
  padding: 1px;
  font-family: "LanTingYaHei";
}
#app .border .border_right .tab .dad {
  width: 50%;
  border-radius: 13px;
}
#app .border .border_right .tab .mum {
  width: 50%;
  border-radius: 13px;
}
#app .border .border_right .tab .active {
  color: white;
  background: #5fa5ef;
}
#app .container_left {
  position: absolute;
  top: 179px;
  left: 112px;
  width: 1004px;
  height: 789px;
  background-image: url(./assets/border-1.png);
  background-size: 100% 100%;
  padding-top: 37px;
  padding-left: 28px;
  padding-right: 35px;
  z-index: 1;
}
#app .container_left .left_title {
  border-bottom: 1px solid #6875cd;
}
#app .container_left .left_title img {
  width: 140px;
  height: 32px;
  margin-bottom: 14px;
}
#app .container_left .left_content {
  height: 700px;
  display: flex;
  flex-flow: column;
  justify-content: space-around;
}
#app .container_left .left_content .item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#app .container_left .left_content .item .item_left {
  width: 439px;
}
#app .container_left .left_content .item .item_left .item_top {
  border-bottom: 1px solid #6875cd;
  margin-bottom: 17px;
  display: flex;
  justify-content: space-between;
}
#app .container_left .left_content .item .item_left .item_top .item_text {
  color: white;
  font-size: 24px;
  line-height: 24px;
}
#app .container_left .left_content .item .item_left .progress {
  width: 438px;
  background-image: url(./assets/progress.png);
  height: 12px;
  position: relative;
}
#app .container_left .left_content .item .item_left .progress .cle {
  width: 21px;
  height: 21px;
  border-radius: 50%;
  border: 2px solid #d548e4;
  background: white;
  position: absolute;
  right: 0;
  top: -4.5px;
}

#app .container_right {
  padding-top: 37px;
  padding-left: 29px;
  padding-right: 33px;
  position: absolute;
  top: 179px;
  right: 88px;
  width: 479px;
  height: 789px;
  background-image: url(./assets/border-2.png);
  background-size: 100% 100%;
  z-index: 1;
}
#app .container_right .right_title {
  border-bottom: 1px solid #6875cd;
  display: flex;
  align-items: flex-end;
}
#app .container_right .right_title img {
  width: 140px;
  height: 32px;
  margin-bottom: 14px;
}
#app .container_right .right_title span {
  color: white;
  font-size: 14px;
  line-height: 14px;
  margin-bottom: 14px;
  margin-left: 31px;
  opacity: 0.5;
}
#cleChart {
  width: 272px;
  height: 272px;
  margin: 27px auto 0px;
}

#app .container_right .el-select {
  width: 300px;
}
#app .container_right .el-select-dropdown {
  width: 300px;
  border: 1px solid #72c7fa;
  background-color: #05375a !important;
}
#app .container_right .el-select-dropdown__item {
  color: #72c7fa;
}
#app .container_right .el-popper .popper__arrow {
  display: none;
}
#app .container_right .el-select-dropdown__item:hover {
  background: #1b5479;
  color: #72c7fa;
}
#app .container_right .el-select-dropdown__item.hover {
  background: #1b5479;
}
#app .container_right .selected {
  background: #1b5479;
  color: #72c7fa;
}
#app
  .container_right
  .el-select
  .el-input__suffix
  .el-input__suffix-inner
  .el-icon-arrow-up:before {
  color: #72c7fa !important;
}
#app .container_right .el-select .el-input__inner::placeholder {
  color: #72c7fa !important;
}
#app .container_right .el-select .el-input input {
  color: #72c7fa !important;
}
#app .container_right .el-select .el-input__inner {
  background: none !important;
  border-color: #72c7fa;
}
#app .container_right .right_item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 50px;
}
#app .container_right .right_item .text {
  color: white;
  font-size: 16px;
  font-weight: 900;
  font-style: italic;
  letter-spacing: 2px;
}
#app .container_left_height {
  position: absolute;
  top: 179px;
  left: 112px;
  width: 1004px;
  height: 789px;
  background-image: url(./assets/border-1.png);
  background-size: 100% 100%;
  padding-top: 37px;
  padding-left: 28px;
  padding-right: 35px;
  z-index: 2;
}
#app .container_left_height .left_title {
  border-bottom: 1px solid #6875cd;
  display: flex;
  justify-content: space-between;
}
#app .container_left_height .left_title img {
  margin-bottom: 14px;
}
#app .container_left_height .left_content {
  padding-top: 26px;
  display: flex;
  justify-content: space-between;
}
#app .container_left_height .left_content .left {
  width: 430px;
}
#app .container_left_height .left_content .left .item .item_top {
  border-bottom: 1px solid #6875cd;
  margin-bottom: 19px;
}
#app .container_left_height .left_content .left .item .item_title {
  display: flex;
  align-items: center;
  margin-bottom: 9px;
}
#app .container_left_height .left_content .left .item .item_title .icon {
  margin-left: 8px;
  margin-right: 23px;
  width: 7px;
  height: 7px;
  background: #72c7fa;
  border-radius: 50%;
}
#app .container_left_height .left_content .left .item .item_title .text {
  color: white;
  opacity: 0.8;
  font-size: 20px;
  line-height: 20px;
}
#app .container_left_height .left_content .left .item .item_text {
  color: white;
  opacity: 0.6;
  font-size: 14px;
  line-height: 20px;
  line-height: 24px;
  margin-bottom: 41px;
  margin-left: 39px;
}
#app .container_left_height .left_content .right {
  width: 430px;
}
#app .container_left_height .left_content .right .item .item_top {
  border-bottom: 1px solid #6875cd;
  margin-bottom: 19px;
}
#app .container_left_height .left_content .right .item .item_title {
  display: flex;
  align-items: center;
  margin-bottom: 9px;
}
#app .container_left_height .left_content .right .item .item_title .icon {
  margin-left: 8px;
  margin-right: 23px;
  width: 7px;
  height: 7px;
  background: #72c7fa;
  border-radius: 50%;
}
#app .container_left_height .left_content .right .item .item_title .text {
  color: white;
  opacity: 0.8;
  font-size: 20px;
  line-height: 20px;
}
#app .container_left_height .left_content .right .item .item_text {
  color: white;
  opacity: 0.6;
  font-size: 14px;
  line-height: 20px;
  line-height: 24px;
  margin-bottom: 20px;
  margin-left: 39px;
}
#app .container_left_height .left_content .right .item .item_text span {
  opacity: 1;
  color: #72c7fa;
  font-weight: 900;
  font-size: 14px;
}

#app .container_right_height {
  padding-top: 37px;
  padding-left: 29px;
  padding-right: 33px;
  position: absolute;
  top: 179px;
  right: 88px;
  width: 479px;
  height: 789px;
  background-image: url(./assets/border-2.png);
  background-size: 100% 100%;
  z-index: 2;
}
#app .container_right_height .right_title {
  border-bottom: 1px solid #6875cd;
  display: flex;
  align-items: flex-end;
}
#app .container_right_height .right_title img {
  width: 140px;
  height: 32px;
  margin-bottom: 14px;
}
#app .container_right_height .right_title span {
  color: white;
  font-size: 14px;
  line-height: 14px;
  margin-bottom: 14px;
  margin-left: 31px;
  opacity: 0.5;
}
#cleChart1 {
  width: 272px;
  height: 272px;
  margin: 50px auto 10px;
}
#app .container_right_height .tip {
  text-align: center;
  color: white;
  font-size: 14px;
  opacity: 0.8;
  margin-bottom: 49px;
}
#app .container_right_height .tip1 {
  text-align: center;
  color: white;
  font-size: 18px;
  margin-bottom: 49px;
  line-height: 35px;
  padding: 36px 0;
  border-top: 1px solid #6875cd;
  border-bottom: 1px solid #6875cd;
}
#app .container_right_height .button {
  width: 240px;
  height: 46px;
  background-color: #47b4ff;
  margin: 15px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 25px;
}

#app .container_left_low {
  position: absolute;
  top: 179px;
  left: 112px;
  width: 1004px;
  height: 789px;
  background-image: url(./assets/border-1.png);
  background-size: 100% 100%;
  padding-top: 37px;
  padding-left: 28px;
  padding-right: 35px;
  z-index: 2;
}
#app .container_left_low .left_title1 {
  border-bottom: 1px solid #6875cd;
  display: flex;
  justify-content: space-between;
}
#app .container_left_low .left_title1 img {
  margin-bottom: 14px;
}
#app .container_left_low .left_content {
  padding-top: 26px;
  display: flex;
  justify-content: space-between;
}
#app .container_left_low .left_content .left {
  width: 430px;
}
#app .container_left_low .left_content .left .item .item_top {
  border-bottom: 1px solid #6875cd;
  margin-bottom: 19px;
}
#app .container_left_low .left_content .left .item .item_title {
  display: flex;
  align-items: center;
  margin-bottom: 9px;
}
#app .container_left_low .left_content .left .item .item_title .icon {
  margin-left: 8px;
  margin-right: 23px;
  width: 7px;
  height: 7px;
  background: #72c7fa;
  border-radius: 50%;
}
#app .container_left_low .left_content .left .item .item_title .text {
  color: white;
  opacity: 0.8;
  font-size: 20px;
  line-height: 20px;
}
#app .container_left_low .left_content .left .item .item_text {
  color: white;
  opacity: 0.6;
  font-size: 14px;
  line-height: 20px;
  line-height: 24px;
  margin-bottom: 41px;
  margin-left: 39px;
}
#app .container_left_low .left_content .right {
  width: 430px;
}
#app .container_left_low .left_content .right .item .item_top {
  border-bottom: 1px solid #6875cd;
  margin-bottom: 19px;
}
#app .container_left_low .left_content .right .item .item_title {
  display: flex;
  align-items: center;
  margin-bottom: 9px;
}
#app .container_left_low .left_content .right .item .item_title .icon {
  margin-left: 8px;
  margin-right: 23px;
  width: 7px;
  height: 7px;
  background: #72c7fa;
  border-radius: 50%;
}
#app .container_left_low .left_content .right .item .item_title .text {
  color: white;
  opacity: 0.8;
  font-size: 20px;
  line-height: 20px;
}
#app .container_left_low .left_content .right .item .item_text {
  color: white;
  opacity: 0.6;
  font-size: 14px;
  line-height: 20px;
  line-height: 24px;
  margin-bottom: 41px;
  margin-left: 39px;
}
#app .container_left_low .left_content .right .item .item_text span {
  opacity: 1;
  color: #72c7fa;
  font-weight: 900;
  font-size: 14px;
}
</style>
